<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ id ? $trans("edit_group") : $trans("add_group") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="form_group" class="mt-2" @submit.prevent="handleSave">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col class="pa-0">
              <v-text-field
                v-model="name"
                :rules="[rules.required, rules.maxChars(30)]"
                :label="$trans('group')"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn
          :disabled="isLoading || !name"
          color="accent_light"
          text
          type="submit"
        >
          {{ $trans("save") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AddGroupDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      name: null,
      id: null,
      groupCopy: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  watch: {
    "getConfirmDialog.data": function () {
      this.fillForm();
    },
  },
  created() {
    this.fillForm();
  },
  methods: {
    ...mapActions({
      createGroup: "group/create",
      updateGroup: "group/update",
      refreshGroups: "group/refreshGroups",
      fetchGroups: "group/fetchAll",
      setNewGroup: "group/setNewGroup",
    }),
    fillForm() {
      if (this.getConfirmDialog.data) {
        const group = this.$helpers.cloneObject(this.getConfirmDialog.data);
        this.groupCopy = group;
        this.name = group.name;
        this.id = group.id;
      }
    },
    handleSave() {
      if (this.$refs.form_group.validate()) {
        this.isLoading = true;
        this.$emit("loading", true);
        this.getConfirmDialog.data ? this.update() : this.add();
      }
    },
    async add() {
      try {
        pushEvent("createGroup");

        const group = await this.createGroup(this.name);
        await this.fetchGroups();
        await this.refreshGroups();
        await this.setNewGroup(group);
        successNotification("added_group");
      } catch (err) {
        errorNotification("error_occurred", err);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
    async update() {
      try {
        pushEvent("updateGroup");

        await this.updateGroup({ name: this.name, id: this.id });
        await this.fetchGroups();
        await this.clearCache("user-list-v2");
        await this.refreshGroups();
        successNotification("update_success");
      } catch (err) {
        errorNotification("error_occurred", err);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
